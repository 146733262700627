import React from "react"
import { Link } from "gatsby"
import Bio from "./bio"
import Clouds from "./clouds"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <div>
        <h1 className="title-font title-center color-blue header-font-size">
          <Link to="/">{title}</Link>
        </h1>
        <Bio />
      </div>
    )
  } else {
    header = (
      <div className="nav-flex">
        <Link className="header-link-home title-font card-title-font-size" to="/">
          {title}
        </Link>
        <Bio />
      </div>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>

      <main>{children}</main>
      <footer>
        ✞ Totus Tuus Mariae
        <Clouds />
      </footer>
    </div>
  )
}

export default Layout
