/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Instagram from "./instagram"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          social {
            instagram
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const social = data.site.siteMetadata?.social

  return (
    <div className="title-center">
      <p>
        <a target="_blank" rel="noreferrer" href={`https://instagram.com/${social?.instagram || ``}`}>
          <Instagram />
        </a>
      </p>
    </div>
  )
}

export default Bio
